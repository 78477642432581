* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

body {
  max-width: 1920px;
  height: 1080px;
  margin: 0 auto;
  background-color: #15365f;
  border: 20px solid #102b4b;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.App {
  text-align: center;
}
.App .counter {
  color: #fff;
  font-family: "Roboto Mono", sans-serif;
  font-size: 150px;
  text-shadow: 15px 15px 15px #000;
}

.App-counter {
  font-family: "Roboto Mono", sans-serif;
  font-size: 80px;
  color: #FFF;
  position: absolute;
  top: 50px;
  left: 75px;
}

.App-logo {
  width: 35%;
  margin: 0 0 60px;
}

.App-qr-code {
  height: 400px;
}

.box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.one {
  flex: 2 1;
}

.two {
  width: 450px;
  text-align: right;
}

.tree {
  flex: 2 1;
  padding: 0 45px;
}
.tree p {
  font-family: "Roboto Mono", sans-serif;
  font-size: 45px;
  color: #FFF;
  padding: 65px 0;
}

.leaderboard {
  max-width: 90%;
  width: 100%;
  border-radius: 12px;
  margin: 0 auto;
}
.leaderboard__profiles {
  border-radius: 0 0 12px 12px;
  padding: 15px 15px 20px;
  display: grid;
  row-gap: 10px;
}
.leaderboard__profile {
  display: grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  padding: 10px 30px 10px 10px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
  background-color: #fff;
}
.leaderboard__picture {
  max-width: 100%;
  width: 160px;
  border-radius: 50%;
  box-shadow: 0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6;
}
.leaderboard__name {
  color: #85899a;
  font-weight: 600;
  font-size: 60px;
  letter-spacing: 0.64px;
  text-align: left;
}

/** Animations */
.an {
  animation-name: flo;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  position: relative;
  top: 0;
  left: 20px;
}

@keyframes flo {
  50% {
    transform: translateY(80px);
  }
}

